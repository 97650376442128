import { FC } from 'react';
import PropTypes from 'prop-types';
import { User } from '../types/user';
import { ETemplateType } from '../templates/types';
import { AccountPopover as AccountPopoverDefault } from '../templates/default/components/account-popover';
import { AccountPopover as AccountPopoverAuthentique } from '../templates/authentique/components/account-popover';
import { AccountPopover as AccountPopoverEbay } from '../templates/ebay/components/account-popover';
import { AccountPopover as AccountPopoverVeraAIOperator } from '../templates/vera-operator/components/account-popover';

interface AccountPopoverProps {
  currentUser?: User;
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <AccountPopoverDefault {...props} />;
    case ETemplateType.AUTHENTIQUE:
      return <AccountPopoverAuthentique {...props} />;
    case ETemplateType.EBAY:
      return <AccountPopoverEbay {...props} />;
    case ETemplateType.VERA_AI_OPERATOR:
      return <AccountPopoverVeraAIOperator {...props} />;
  }
};

AccountPopover.propTypes = {
  // @ts-ignore
  currentUser: PropTypes.object,
};
