import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, DialogProps, List, ListItem, Typography } from '@material-ui/core';
import { AccessTime as ClockIcon } from '@material-ui/icons';
import { ExclamationOutlinedFilledYellow as WarningIcon } from '../../../../icons/exclamation-outlined-filled-yellow';
import { CalibrationDialogType } from '../../utils/calibration-helper';
import { CustomDialog } from '../custom-dialog';
import { ButtonContinue } from './button-continue';

type CloseHandler = DialogProps['onClose'];

const noBackdropCloseHandler =
  (closeHandler?: CloseHandler): CloseHandler =>
  (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    closeHandler(event, reason);
  };

interface InfoDialogProps {
  open: boolean;
  onClose: CloseHandler;
  type: CalibrationDialogType;
  onContinue: () => void;
}

export const InfoDialog: FC<InfoDialogProps> = ({ open, onClose, type, onContinue }) => {
  let title = '';
  let content = <></>;
  let handleClose = onClose;
  let hideClose = false;
  switch (type) {
    default:
      return <></>;

    case CalibrationDialogType.CAMERA_STREAM_DIALOG:
      title = 'Check Stream sharpness';
      content = (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gridGap: '1em',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gridGap: '1em',
            }}
          >
            <Box>
              <Typography variant="body1" sx={{ fontSize: '40px !important' }}>
                You will now check the&nbsp;sharpness of the&nbsp;camera against sharp and blurry
                examples.
              </Typography>
            </Box>
            <ButtonContinue id="calibration-dialog-1-button-continue" onClick={onContinue} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
            }}
          >
            <img src="/assets/CalibFlow-camerastream.png" alt="Camera Sharpness Instructions" />
          </Box>
        </Box>
      );
      break;

    case CalibrationDialogType.ADJUST_FOCUS_DIALOG:
      title = 'Adjust the Camera sharpness';
      content = (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gridGap: '1em',
            }}
          >
            <Box>
              <List
                sx={{
                  fontSize: '40px',
                  listStyleType: 'auto',
                  pl: '60px',
                  '& .MuiListItem-root': {
                    display: 'list-item',
                    p: 0,
                  },
                }}
              >
                <ListItem>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: '40px !important',
                    }}
                  >
                    Loosen the&nbsp;Fixing ring.
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1" sx={{ fontSize: '40px !important' }}>
                    Then, turn the&nbsp;Focus ring until the image is as sharp as the&nbsp;example.
                  </Typography>
                </ListItem>
              </List>
              <Typography variant="body1" sx={{ fontSize: '40px !important' }} mt={2}>
                <strong>*Do not touch or turn the&nbsp;Aperture ring.</strong> Let it move with
                the&nbsp;Focus ring.
              </Typography>
            </Box>
            <ButtonContinue id="calibration-dialog-2-button-continue" onClick={onContinue} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img src="/assets/CalibFlow-adjustfocus.jpg" alt="Adjust Focus" />
          </Box>
        </Box>
      );
      break;

    case CalibrationDialogType.REVIEW_DIALOG:
      title = 'Calibration review';
      hideClose = true;
      handleClose = noBackdropCloseHandler(onClose);
      content = (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            gridGap: '.5em',
          }}
        >
          <CircularProgress color="inherit" size={50} thickness={5} />
          <Typography variant="body1" sx={{ fontSize: '40px !important' }}>
            The&nbsp;data is now being uploaded for review. Please wait for the&nbsp;process to
            finish.
          </Typography>
        </Box>
      );
      break;

    case CalibrationDialogType.SECURE_FOCUS_DIALOG:
      title = 'Secure the Fixing ring';
      hideClose = true;
      handleClose = noBackdropCloseHandler(onClose);
      content = (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gridGap: '1em',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gridGap: '2em',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gridGap: '.5em',
              }}
            >
              <WarningIcon sx={{ fontSize: '90px' }} />
              <Typography variant="body1" sx={{ fontSize: '40px !important' }}>
                If you have been adjusting the camera, ensure the&nbsp;Fixing ring is tightened to
                prevent the&nbsp;camera from becoming decalibrated.
              </Typography>
            </Box>
            <ButtonContinue id="calibration-dialog-3-button-continue" onClick={onContinue} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img src="/assets/CalibFlow-ifblurry-tighten.jpg" alt="Secure Focus" />
          </Box>
        </Box>
      );
      break;

    case CalibrationDialogType.APPROVAL_DIALOG:
      title = 'Calibration done';
      hideClose = true;
      handleClose = noBackdropCloseHandler(onClose);
      content = (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gridGap: '2em',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gridGap: '.5em',
            }}
          >
            <ClockIcon sx={{ fontSize: '90px' }} />
            <Typography
              variant="body1"
              sx={{
                fontSize: '40px !important',
              }}
            >
              The&nbsp;calibration process is finished. The&nbsp;calibration picture has been
              uploaded for review, if needed.
            </Typography>
          </Box>
          <ButtonContinue id="calibration-dialog-4-button-done" onClick={onContinue}>Done</ButtonContinue>
        </Box>
      );
      break;
  }

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      hideClose={hideClose}
      title={title}
      fullWidth
      maxWidth="lg"
    >
      {content}
    </CustomDialog>
  );
};

InfoDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  type: PropTypes.number,
  onContinue: PropTypes.func,
};
