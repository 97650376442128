import type { FC } from 'react';
import { ETemplateType } from './templates/types';
import { App as DefaultApp } from './templates/default/app';
import { App as VeraAIOperatorApp } from './templates/vera-operator/app';

export const App: FC = () => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <DefaultApp />;
    case ETemplateType.VERA_AI_OPERATOR:
      return <VeraAIOperatorApp />;
  }
};
