import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { FC } from 'react';
import { CheckCircle as SharpIcon } from '../../../../icons/check-circle';
import { XCircle as BlurryIcon } from '../../../../icons/x-circle';
import { ButtonContinue } from './button-continue';

interface ComparisonContainerProps {
  streamUrl: string;
  onBlurry: () => void;
  onSharp: () => void;
}

export const ComparisonContainer: FC<ComparisonContainerProps> = ({
  streamUrl,
  onBlurry,
  onSharp,
}) => (
  <Box
    sx={{
      height: '100%',
      display: 'grid',
      columnGap: '10px',
      gridTemplateColumns: 'repeat(3, calc((100% - 20px) / 3))',
      gridTemplateRows: 'auto 120px',
      background: '#4D4D4D',
      overflow: 'hidden',
      img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
      '& > div': {
        overflow: 'hidden',
      },
    }}
  >
    <Box>
      <img src="/assets/CalibFlow-sharp.jpg" alt="Sharp Example" />
    </Box>
    <Box>
      <img src={streamUrl} alt="Camera Stream" />
    </Box>
    <Box>
      <img src="/assets/CalibFlow-blurry.jpg" alt="Blurry Example" />
    </Box>

    <Typography
      variant="subtitle1"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="#00D955"
    >
      <SharpIcon
        sx={{
          mr: '.5rem',
        }}
      />
      Sharp Example
    </Typography>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gridGap: '5px',
        backgroundColor: '#4D4D4D',
      }}
    >
      <ButtonContinue id="calibration-button-sharp" onClick={onSharp} sx={{ width: '100%' }}>
        Sharp
      </ButtonContinue>
      <ButtonContinue id="calibration-button-blurry" onClick={onBlurry} sx={{ width: '100%' }}>
        Blurry
      </ButtonContinue>
    </Box>
    <Typography
      variant="subtitle1"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="#CC1F2B"
    >
      <BlurryIcon
        sx={{
          mr: '.5rem',
        }}
      />
      Blurry Example
    </Typography>
  </Box>
);

ComparisonContainer.propTypes = {
  streamUrl: PropTypes.string,
  onBlurry: PropTypes.func,
  onSharp: PropTypes.func,
};
