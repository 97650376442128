import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { ETemplateType } from '../templates/types';
import { AuthGuard as AuthGuardDefault } from '../templates/default/components/auth-guard';
import { AuthGuard as AuthGuardAuthentique } from '../templates/authentique/components/auth-guard';
import { AuthGuard as AuthGuardVeraAIOperator } from '../templates/vera-operator/components/auth-guard';

interface AuthGuardProps {
  children: ReactNode;
}

export const AuthGuard: FC<AuthGuardProps> = (props) => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <AuthGuardDefault {...props} />;
    case ETemplateType.AUTHENTIQUE:
      return <AuthGuardAuthentique {...props} />;
    case ETemplateType.VERA_AI_OPERATOR:
      return <AuthGuardVeraAIOperator {...props} />;
  }
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};
