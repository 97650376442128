import { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { ShieldSuccessOutlined as ProtectedIcon } from '../../../../icons/shield-success-outlined';
import { ShieldSuccess as VerifiedIcon } from '../../../../icons/shield-success';
import { ShieldFailOutlined as UnableToProtectIcon } from '../../../../icons/shield-fail-outlined';
import { ShieldFail as NotVerifiedIcon } from '../../../../icons/shield-fail';
import { ShieldUnknown as UnableToVerifyIcon } from '../../../../icons/shield-unknown';
import { EJobTitle, Job } from '../../../../types/job';

const iconSx = {
  fontSize: '5rem',
};

const variants = [
  {
    value: 'protected',
    text: 'Protected',
    icon: <ProtectedIcon sx={iconSx} />,
    color: 'success.main',
  },
  {
    value: 'unableToProtect',
    text: 'Unable To Protect',
    icon: <UnableToProtectIcon sx={iconSx} />,
    color: 'error.main',
  },
  {
    value: 'verified',
    text: 'Verified',
    icon: <VerifiedIcon sx={iconSx} />,
    color: 'success.main',
  },
  {
    value: 'notVerified',
    text: 'Not Verified',
    icon: <NotVerifiedIcon sx={iconSx} />,
    color: 'error.main',
  },
  {
    value: 'unable',
    text: 'Unable to Verify',
    icon: <UnableToVerifyIcon sx={iconSx} />,
    color: 'neutral.main',
  },
];

interface JobsStatusCellProps {
  job: Job;
}

const getStatus = (job: Job): string => {
  if (job.title === EJobTitle.PROTECTION || job.title === EJobTitle.REGISTRATION) {
    if (job.resultCode === 0) {
      return 'protected';
    }
    return 'unableToProtect';
  }

  if (job.resultCode === 0) {
    return 'verified';
  }

  if (job.resultCode === 1) {
    return 'notVerified';
  }

  return 'unable';
};

export const JobsStatusCell: FC<JobsStatusCellProps> = ({ job }) => {
  const variant = variants.find((v) => v.value === getStatus(job));

  return (
    <Box
      sx={{
        display: 'flex',
        gridGap: '1em',
        alignItems: 'center',
      }}
    >
      {variant.icon}
      <Typography className="job-status" variant="subtitle1" color={variant.color}>
        {variant.text}
      </Typography>
    </Box>
  );
};

JobsStatusCell.propTypes = {
  // @ts-ignore
  job: PropTypes.object.isRequired,
};
