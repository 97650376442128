import { useState, useEffect, useMemo } from 'react';
import type { FC } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Drawer, List } from '@material-ui/core';
import { DashboardNavbarMenuItem } from './dashboard-navbar-menu-item';
import { SUPER_ADMIN_TENANT } from '../types/tenant';
import { useAuth } from '../hooks/use-auth';
import { getAdminItems, getTenantItems, DashboardMenuItem } from '../utils/dashboard-menu-items';

interface DashboardNavbarMenuProps {
  onClose: () => void;
  open: boolean;
}

export const DashboardNavbarMenu: FC<DashboardNavbarMenuProps> = (props) => {
  const { open, onClose } = props;
  const { pathname } = useLocation();
  const { tenant } = useAuth();
  const [openedItem, setOpenedItem] = useState<DashboardMenuItem | null>(null);
  const [activeItem, setActiveItem] = useState<DashboardMenuItem | null>(null);
  const [activeHref, setActiveHref] = useState('');

  const items = useMemo(
    () =>
      tenant?.id === SUPER_ADMIN_TENANT.id
        ? getAdminItems()
        : getTenantItems().map((i) => ({ ...i, href: i.href.replace(':tenantId', tenant.id) })),
    [tenant],
  );

  const handleOpenItem = (item: DashboardMenuItem): void => {
    if (openedItem === item) {
      setOpenedItem(null);
      return;
    }

    setOpenedItem(item);
  };

  useEffect(() => {
    let foundActive = false;

    items.forEach((item) => {
      if (item.items) {
        for (let index = 0; index < item.items.length; index++) {
          const active = matchPath({ path: item.items[index].href, end: true }, pathname);

          if (active) {
            foundActive = true;
            setActiveItem(item);
            setActiveHref(item.items[index].href);
            setOpenedItem(item);
            break;
          }
        }
      } else {
        const active = !!matchPath({ path: item.href, end: true }, pathname);

        if (active) {
          foundActive = true;
          setActiveItem(item);
          setOpenedItem(item);
        }
      }
    });

    // If active item not found, try parents
    if (!foundActive) {
      items.forEach((item) => {
        if (item.href) {
          const active = !!matchPath({ path: item.href, end: false }, pathname);
          if (active) {
            setActiveItem(item);
            setOpenedItem(item);
          }
        }
      });
    }
  }, [pathname, items]);

  return (
    <Drawer
      anchor="top"
      onClose={onClose}
      open={open}
      transitionDuration={0}
      ModalProps={{
        BackdropProps: {
          invisible: true,
        },
      }}
      PaperProps={{
        sx: {
          backgroundColor: '#2B2F3C',
          color: '#B2B7C8',
          display: 'flex',
          flexDirection: 'column',
          top: 64,
          maxHeight: 'calc(100% - 64px)',
          width: '100vw',
        },
      }}
    >
      <List>
        {items.map((item) => (
          <DashboardNavbarMenuItem
            active={activeItem?.title === item.title}
            activeHref={activeHref}
            key={item.title}
            onClose={onClose}
            onOpenItem={() => handleOpenItem(item)}
            open={openedItem?.title === item.title}
            {...item}
          />
        ))}
      </List>
    </Drawer>
  );
};

DashboardNavbarMenu.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
