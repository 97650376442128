import { Box } from '@material-ui/core';
import { FC, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useScanner } from '../../hooks/use-scanner';
import { EItemFace, ImageItem } from './image-item';
import { EJobResultCode } from '../../../../types/job';

interface ImageContianerProps {
  face?: EItemFace;
  isImageLoading?: boolean;
  frontImageBase64?: string;
  backImageBase64?: string;
  frontResultCode?: EJobResultCode;
  backResultCode?: EJobResultCode;
}

export const ImageContianer: FC<ImageContianerProps> = ({
  face,
  isImageLoading,
  frontImageBase64,
  backImageBase64,
  frontResultCode,
  backResultCode,
}) => {
  const { getStream, startStream, stopStream } = useScanner();

  useEffect(
    () => () => {
      stopStream();
    },
    [],
  );

  useEffect(() => {
    if (isImageLoading) {
      stopStream();
    } else if (
      (!frontImageBase64 && face === EItemFace.Front) ||
      (!backImageBase64 && face === EItemFace.Back)
    ) {
      startStream();
    }
  }, [isImageLoading, frontImageBase64, backImageBase64, face]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        background: '#4D4D4D',
        color: '#ffffff',
      }}
    >
      <ImageItem
        id="job-image-front"
        face={EItemFace.Front}
        active={face === EItemFace.Front}
        isLoading={
          (isImageLoading && face === EItemFace.Front) ||
          (face === EItemFace.Back && !frontImageBase64) ||
          (!face && !frontImageBase64)
        }
        streamUrl={getStream()}
        imageBase64={frontImageBase64}
        resultCode={frontResultCode}
      />

      <ImageItem
        id="job-image-back"
        face={EItemFace.Back}
        active={face === EItemFace.Back}
        isLoading={(isImageLoading && face === EItemFace.Back) || (!face && !backImageBase64)}
        streamUrl={getStream()}
        imageBase64={backImageBase64}
        resultCode={backResultCode}
      />
    </Box>
  );
};

ImageContianer.propTypes = {
  face: PropTypes.any,
  isImageLoading: PropTypes.bool,
  frontImageBase64: PropTypes.string,
  backImageBase64: PropTypes.string,
  frontResultCode: PropTypes.number,
  backResultCode: PropTypes.number,
};
