export const sendSlackError = async (
  sendSlackMethod: (message: any) => void,
  source: string,
  baseLocation: string,
  error: any,
  defaultMessage?: string,
) => {
  let location = baseLocation;
  if (error?.config?.baseURL && error?.config?.url && error.config.method) {
    location = `${location} - (${error.config.method}) ${error.config.baseURL}${error.config.url}`;
  }

  const errorMessage =
    error?.response?.data?.error?.message ?? error?.message ?? error ?? defaultMessage;

  const message = {
    icon_emoji: 'pepeintrouble',
    blocks: [
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*Runtime error occured on station* :alert: <!channel>\n>*Source:* ${source}\n>*Location:* ${location}\n>*Message:* ${errorMessage}`,
        },
      },
    ],
  };

  sendSlackMethod(message);
};
