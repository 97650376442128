import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { AccountPopover } from '../../../../components/account-popover';
import { Logo } from '../../../../components/logo';
import { useAuth } from '../../../../hooks/use-auth';

interface JobsNavbarProps {
  query: boolean;
  onQueryChange: (value: boolean) => void;
}

export const JobsNavbar: FC<JobsNavbarProps> = ({ query, onQueryChange }) => {
  const { user: currentUser } = useAuth();

  return (
    <AppBar elevation={0} sx={{ color: 'text.primary', backgroundColor: '#F2F2F2' }}>
      <Toolbar
        disableGutters
        sx={{
          alignItems: 'normal',
          display: 'flex',
          minHeight: '120px !important',
          pl: 3,
        }}
      >
        <Box
          component={RouterLink}
          to="/"
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            width: 90,
          }}
        >
          <Logo emblemOnly variant="dark" size="large" />
        </Box>
        <Divider
          flexItem
          orientation="vertical"
          sx={{
            // borderWidth: '2px',
            borderColor: '#D9D9D9',
            mx: 3,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography id="activity-navbar-title" variant="h5">Activity</Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              border: '1px solid #999999',
              borderRadius: 1,
            }}
          >
            <FormControlLabel
              label="Only Not Verified"
              labelPlacement="end"
              control={
                <Checkbox
                  id="activity-navbar-checkbox-filter-notverified"
                  checked={query}
                  onChange={(_, value) => onQueryChange(value)}
                />
              }
              sx={{
                m: 0,
                px: 4,
                py: 2,
              }}
            />
          </Box>
        </Box>
        <AccountPopover currentUser={currentUser} />
      </Toolbar>
      <Divider
        sx={{
          borderColor: '#D9D9D9',
        }}
      />
    </AppBar>
  );
};

JobsNavbar.propTypes = {
  query: PropTypes.bool,
  onQueryChange: PropTypes.func,
};
