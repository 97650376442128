import type { FC } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, Divider, IconButton, Toolbar, Typography } from '@material-ui/core';
import { X as XIcon } from '../../../../icons/x';
import { ButtonNext } from './button-next';
import { ButtonPrev } from './button-prev';
import { CalibrationStep } from '../../utils/calibration-helper';

interface CalibrationNavbarProps {
  step: CalibrationStep;
  onPrevStep: () => void;
  onNextStep: () => void;
  onClose: () => void;
}

export const CalibrationNavbar: FC<CalibrationNavbarProps> = ({
  step,
  onPrevStep,
  onNextStep,
  onClose,
}) => (
  <AppBar elevation={0} sx={{ color: 'text.primary', backgroundColor: '#F2F2F2' }}>
    <Toolbar
      disableGutters
      sx={{
        alignItems: 'normal',
        display: 'flex',
        minHeight: '120px !important',
        pl: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography id="calibration-navbar-title" variant="h5">Camera Calibration</Typography>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ButtonPrev id="calibration-button-previous" step={step} onClick={onPrevStep} />
        <ButtonNext id="calibration-button-next" step={step} onClick={onNextStep} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IconButton
          id="calibration-button-close"
          size="large"
          onClick={onClose}
          title="Close"
          color="inherit"
          sx={{
            borderRadius: '0 !important',
            background: '#d9d9d9',
            width: 120,
            height: 120,
          }}
        >
          <XIcon fontSize="large" />
        </IconButton>
      </Box>
    </Toolbar>
    <Divider
      sx={{
        borderColor: '#D9D9D9',
      }}
    />
  </AppBar>
);

CalibrationNavbar.propTypes = {
  step: PropTypes.number,
  onPrevStep: PropTypes.func,
  onNextStep: PropTypes.func,
  onClose: PropTypes.func,
};
