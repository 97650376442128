import type { FC } from 'react';
import Proptypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Status } from '../status';
import { EJobResultType, EJobTitle, FlagType, Job } from '../../types/job';
import { getJobRunningStatus, getResultStatus } from '../../utils/jobs-helper';
import { Flag as FlagIcon } from '../../icons/flag';

interface JobStatusProps {
  job: Job;
  reverseOrder?: boolean;
}

interface StatusVariant {
  color: string;
  label: string;
}

const DEFAULT_STATUS_VARIANT: StatusVariant = {
  color: 'text.secondary',
  label: 'Unknown status',
};

const getVariant = (job: Job): StatusVariant => {
  const result = getResultStatus(job);
  const isRunning = getJobRunningStatus(job);
  switch (job.title) {
    default:
      return DEFAULT_STATUS_VARIANT;

    // Authentication
    case EJobTitle.PROTECTION: {
      if (isRunning) {
        return { color: 'primary.main', label: 'Protection in progress' };
      }
      if (result === EJobResultType.OK) {
        return { color: 'info.main', label: 'Protected' };
      }
      return { color: 'error.main', label: 'Unable To Protect' };
    }

    case EJobTitle.VERIFICATION: {
      if (isRunning) {
        return { color: 'primary.main', label: 'Verification in progress' };
      }
      if (result === EJobResultType.OK) {
        return { color: 'success.main', label: 'Verified' };
      }
      if (result === EJobResultType.NOT_VERIFIED) {
        return { color: 'error.main', label: 'Not Verified' };
      }

      return { color: 'text.primary', label: 'Unable To Verify' };
    }

    // Identification
    case EJobTitle.REGISTRATION: {
      if (isRunning) {
        return { color: 'primary.main', label: 'Registration in progress' };
      }
      if (result === EJobResultType.OK) {
        return { color: 'info.main', label: 'Registered' };
      }
      return { color: 'error.main', label: 'Unable To Register' };
    }

    case EJobTitle.IDENTIFICATION: {
      if (isRunning) {
        return { color: 'primary.main', label: 'Identification in progress' };
      }
      if (result === EJobResultType.OK) {
        return { color: 'success.main', label: 'Identified' };
      }
      if (result === EJobResultType.NOT_VERIFIED) {
        return { color: 'error.main', label: 'Not registered item' };
      }
      return { color: 'error.main', label: 'Unable To Identify' };
    }
  }
};

export const JobStatus: FC<JobStatusProps> = ({ job, reverseOrder }) => {
  const statusVariant = getVariant(job);

  return (
    <Box
      sx={{
        display: 'flex',
        gridGap: '1em',
        flexDirection: reverseOrder ? 'row-reverse' : 'row',
      }}
    >
      <Status color={statusVariant.color} label={statusVariant.label} />
      {job.flag === FlagType.Incorrect && <FlagIcon color="error" />}
    </Box>
  );
};

JobStatus.propTypes = {
  job: Proptypes.any.isRequired,
  reverseOrder: Proptypes.bool,
};
