import type { FC, ReactNode, ElementType } from 'react';
import PropTypes from 'prop-types';
import { Box, ListItem, ListItemText, Typography } from '@material-ui/core';
import type { ListItemProps } from '@material-ui/core';

interface PropertyListItemProps extends ListItemProps {
  align?: string;
  children?: ReactNode;
  component?: ElementType;
  label: string;
  value?: string;
}

export const PropertyListItem: FC<PropertyListItemProps> = (props) => {
  const { align, children, component, value, label, ...other } = props;

  return (
    <ListItem
      component={component}
      disableGutters
      {...other}
      sx={{
        px: 6,
        py: 3,
        ...other.sx,
      }}
    >
      <ListItemText
        disableTypography
        primary={
          <Typography
            color="textPrimary"
            sx={{ minWidth: align === 'vertical' ? 'inherit' : 190, pr: 1 }}
            variant="caption"
            className="property-list-item-label"
          >
            {label}
          </Typography>
        }
        secondary={
          <Box
            sx={{
              flex: 1,
              mt: align === 'vertical' ? 0.5 : 0,
            }}
          >
            {children || (
              <Typography variant="subtitle1" lineHeight={1.2} className="property-list-item-value">
                {value}
              </Typography>
            )}
          </Box>
        }
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: align === 'vertical' ? 'column' : 'row',
          my: 0,
        }}
      />
    </ListItem>
  );
};

PropertyListItem.defaultProps = {
  align: 'vertical',
};

PropertyListItem.propTypes = {
  align: PropTypes.string,
  // @ts-ignore
  component: PropTypes.elementType,
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};
