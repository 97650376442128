import { Query } from 'react-query';
import {
  EJobResultCode,
  EJobResultType,
  EJobStatus,
  EJobTitle,
  EStatusFilter,
  FlagType,
  Job,
  Task,
} from '../types/job';
import { EFingerpintTitle } from '../types/fingerprint';
import { FilterOperator } from './filter-operators';

export const REFETCH_RUNNING_JOBS_INTERVAL = 500;

export const REFETCH_OPEN_JOBS_INTERVAL = 1500;

export const getResultStatus = (event: Job | Task): EJobResultType => {
  switch (event?.resultCode) {
    default:
      return EJobResultType.FAILED;
    case EJobResultCode.OK:
      return EJobResultType.OK;
    case EJobResultCode.NOT_VERIFIED:
      return EJobResultType.NOT_VERIFIED;
  }
};

export const getJobRunningStatus = (event?: Job | Task): boolean =>
  [EJobStatus.OPEN, EJobStatus.RUNNING].includes(event?.status);

export const getJobTitle = (title: EJobTitle) => {
  switch (title) {
    case EJobTitle.PROTECTION:
      return 'Protection';
    case EJobTitle.VERIFICATION:
      return 'Verification';
    case EJobTitle.VISUALISATION:
      return 'Visualisation';
    case EJobTitle.REGISTRATION:
      return 'Registration';
    case EJobTitle.IDENTIFICATION:
      return 'Identification';
    default:
      return 'Unknown';
  }
};

export const getTaskTitle = (task: Task) => getJobTitle(task.taskName);

export const getTitle = (event: Job | Task) =>
  getJobTitle((event as Job).title ?? (event as Task).taskName);

export const getJobRefetchInterval = (event: Job | Task, query: Query): number | false => {
  if (event?.status === EJobStatus.RUNNING) {
    return REFETCH_RUNNING_JOBS_INTERVAL;
  }

  if (event?.status === EJobStatus.OPEN) {
    return REFETCH_OPEN_JOBS_INTERVAL;
  }

  if (query.state.error) {
    return false;
  }

  return event ? false : REFETCH_OPEN_JOBS_INTERVAL;
};

export const getFingerprintTitle = (fingerprintTitle?: EFingerpintTitle) => {
  switch (fingerprintTitle) {
    case EFingerpintTitle.PROTECTION:
      return 'Protection';
    case EFingerpintTitle.PROTECTION_FRONT:
      return 'Protection Front';
    case EFingerpintTitle.PROTECTION_BACK:
      return 'Protection Back';
    case EFingerpintTitle.REGISTRATION:
      return 'Registration';
    default:
      return '';
  }
};

export enum EJobsView {
  ALL = 'all',
  PROTECTED = 'protected',
  UNABLE_TO_PROTECT = 'unableToProtect',
  VERIFIED = 'verified',
  UNABLE_TO_VERIFY = 'unableToVerify',
  NOT_VERIFIED = 'notVerified',
  REGISTERED = 'registered',
  UNABLE_TO_REGISTER = 'unableToRegister',
  IDENTIFIED = 'identified',
  UNABLE_TO_IDENTIFY = 'unableToIdentify',
  NOT_IDENTIFIED = 'notIdentified',
  REPORTED = 'reported',
}

export const getJobsRequestStatusParams = (status?: EStatusFilter | EJobsView) => {
  const params: any = {
    'globalFilter[title]': {
      operator: FilterOperator.NOT_EQUAL,
      value: EJobTitle.VISUALISATION,
    },
  };

  switch (status) {
    default:
    case EJobsView.ALL:
      break;

    /// Authentication ///
    case EJobsView.PROTECTED:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.PROTECTION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobResultCode.OK,
      };
      break;
    case EJobsView.UNABLE_TO_PROTECT:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.PROTECTION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.NOT_EQUAL,
        value: EJobResultCode.OK,
      };
      break;
    case EJobsView.VERIFIED:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.VERIFICATION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobResultCode.OK,
      };
      break;
    case EJobsView.UNABLE_TO_VERIFY:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.VERIFICATION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.NOT_IN_ARRAY,
        value: [EJobResultCode.OK, EJobResultCode.NOT_VERIFIED],
      };
      break;
    case EJobsView.NOT_VERIFIED:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.VERIFICATION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobResultCode.NOT_VERIFIED,
      };
      break;

    /// Identification ///
    case EJobsView.REGISTERED:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.REGISTRATION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobResultCode.OK,
      };
      break;
    case EJobsView.UNABLE_TO_REGISTER:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.REGISTRATION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.NOT_EQUAL,
        value: EJobResultCode.OK,
      };
      break;
    case EJobsView.IDENTIFIED:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.IDENTIFICATION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobResultCode.OK,
      };
      break;
    case EJobsView.NOT_IDENTIFIED:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.IDENTIFICATION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.NOT_EQUAL,
        value: EJobResultCode.OK,
      };
      break;
    case EJobsView.UNABLE_TO_IDENTIFY:
      params['globalFilter[title]'] = {
        operator: FilterOperator.EQUAL,
        value: EJobTitle.IDENTIFICATION,
      };
      params['globalFilter[resultCode]'] = {
        operator: FilterOperator.NOT_IN_ARRAY,
        value: [EJobResultCode.OK, EJobResultCode.NOT_VERIFIED],
      };
      break;

    /// Reporting ///
    case EJobsView.REPORTED:
      params['globalFilter[flag]'] = FlagType.Incorrect;
      break;
  }

  return params;
};
