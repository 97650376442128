// Zabbix keys
// Protection sesion elapsed time
export const ZABBIX_KEY_PROTECTION_SESSION_ELAPSED_TIME = 'ebayOperator.protectionElapsedTime';
// Protection session elapsed time without user input
export const ZABBIX_KEY_PROTECTION_SESSION_ELAPSED_TIME_NO_INPUT =
  'ebayOperator.protectionElapsedTimeNoInput';
// Verification session elapsed time
export const ZABBIX_KEY_VERIFICATION_SESSION_ELAPSED_TIME = 'ebayOperator.verificationElapsedTime';
// Verification session elapsed time without user input
export const ZABBIX_KEY_VERIFICATION_SESSION_ELAPSED_TIME_NO_INPUT =
  'ebayOperator.verificationElapsedTimeNoInput';

// Session keys
// Start of the session
const SESSION_KEY_START = 'ebay-operator-session-start';
// Item loaded
const SESSION_KEY_ITEM_LOADED = 'ebay-operator-item-loaded';
// Capturing front start
const SESSION_KEY_CAPTURING_FRONT_START = 'ebay-operator-capturing-front-start';
// Front FP received
const SESSION_KEY_CAPTURING_FRONT_RECEIVE_FP = 'ebay-operator-capturing-front-receive-fp';
// Front HDR received
const SESSION_KEY_CAPTURING_FRONT_RECEIVE_HDR = 'ebay-operator-capturing-front-receive-hdr';
// Capturing front end
const SESSION_KEY_CAPTURING_FRONT_END = 'ebay-operator-capturing-front-end';
// Capturing back available
const SESSION_KEY_CAPTURING_BACK_AVAILABLE = 'ebay-operator-capturing-back-available';
// Capturing back start
const SESSION_KEY_CAPTURING_BACK_START = 'ebay-operator-capturing-back-start';
// Back FP received
const SESSION_KEY_CAPTURING_BACK_RECEIVE_FP = 'ebay-operator-capturing-back-receive-fp';
// Back HDR received
const SESSION_KEY_CAPTURING_BACK_RECEIVE_HDR = 'ebay-operator-capturing-back-receive-hdr';
// Capturing back end
const SESSION_KEY_CAPTURING_BACK_END = 'ebay-operator-capturing-back-end';
// Analysing start
const SESSION_KEY_ANALYSING_START = 'ebay-operator-analysing-start';
// Analysing end
const SESSION_KEY_ANALYSING_END = 'ebay-operator-analysing-end';
// Session end
const SESSION_KEY_END = 'ebay-operator-session-end';

export const setSessionTimestamp = (key: string) => {
  sessionStorage.setItem(key, new Date().toISOString());
};

export const getSessionTimestamp = (key: string) => new Date(sessionStorage.getItem(key));

export const restartSession = () => {
  sessionStorage.clear();
};

export const startNewSession = () => {
  restartSession();
  setSessionTimestamp(SESSION_KEY_START);
};

export const setItemLoadedTimestamp = () => {
  setSessionTimestamp(SESSION_KEY_ITEM_LOADED);
};

export const setCapturingFrontStartTimestamp = () => {
  setSessionTimestamp(SESSION_KEY_CAPTURING_FRONT_START);
};

export const setCapturingFrontReceiveFPTimestamp = () => {
  setSessionTimestamp(SESSION_KEY_CAPTURING_FRONT_RECEIVE_FP);
};

export const setCapturingFrontReceiveHDRTimestamp = () => {
  setSessionTimestamp(SESSION_KEY_CAPTURING_FRONT_RECEIVE_HDR);
};

export const setCapturingFrontEndTimestamp = () => {
  setSessionTimestamp(SESSION_KEY_CAPTURING_FRONT_END);
};

export const setCapturingBackAvailableTimestamp = () => {
  setSessionTimestamp(SESSION_KEY_CAPTURING_BACK_AVAILABLE);
};

export const setCapturingBackStartTimestamp = () => {
  setSessionTimestamp(SESSION_KEY_CAPTURING_BACK_START);
};

export const setCapturingBackReceiveFPTimestamp = () => {
  setSessionTimestamp(SESSION_KEY_CAPTURING_BACK_RECEIVE_FP);
};

export const setCapturingBackReceiveHDRTimestamp = () => {
  setSessionTimestamp(SESSION_KEY_CAPTURING_BACK_RECEIVE_HDR);
};

export const setCapturingBackEndTimestamp = () => {
  setSessionTimestamp(SESSION_KEY_CAPTURING_BACK_END);
};

export const setAnalysingStartTimestamp = () => {
  setSessionTimestamp(SESSION_KEY_ANALYSING_START);
};

export const setAnalysingEndTimestamp = () => {
  setSessionTimestamp(SESSION_KEY_ANALYSING_END);
};

export const setSessionEndTimestamp = () => {
  setSessionTimestamp(SESSION_KEY_END);
};

export const getSessionElapsedTime = () => {
  const start = getSessionTimestamp(SESSION_KEY_START).getTime();
  const end = getSessionTimestamp(SESSION_KEY_END).getTime();
  return (end - start) / 1000;
};

export const getSessionElapsedTimeWithoutUserInput = () => {
  const sessionTime = getSessionElapsedTime();
  const inputBeforeFrontCapture =
    (getSessionTimestamp(SESSION_KEY_CAPTURING_FRONT_START).getTime() -
      getSessionTimestamp(SESSION_KEY_ITEM_LOADED).getTime()) /
    1000;
  const inputBetweenFrontAndBackCapture =
    (getSessionTimestamp(SESSION_KEY_CAPTURING_BACK_START).getTime() -
      getSessionTimestamp(SESSION_KEY_CAPTURING_BACK_AVAILABLE).getTime()) /
    1000;

  return sessionTime - inputBeforeFrontCapture - inputBetweenFrontAndBackCapture;
};

export const getSessionElapsedTimeFromFirstInput = () => {
  const start = getSessionTimestamp(SESSION_KEY_CAPTURING_FRONT_START).getTime();
  const end = getSessionTimestamp(SESSION_KEY_END).getTime();
  return (end - start) / 1000;
};

export const getSessionElapsedTimeFromFirstInputWithoutUserInput = () => {
  const sessionTime = getSessionElapsedTimeFromFirstInput();
  const inputBetweenFrontAndBackCapture =
    (getSessionTimestamp(SESSION_KEY_CAPTURING_BACK_START).getTime() -
      getSessionTimestamp(SESSION_KEY_CAPTURING_BACK_AVAILABLE).getTime()) /
    1000;

  return sessionTime - inputBetweenFrontAndBackCapture;
};
