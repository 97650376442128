import type { FC, MouseEvent } from 'react';
import Proptypes from 'prop-types';
import { Job } from '../../types/job';
import { ETemplateType } from '../../templates/types';
import { JobsTable as JobsTableDefault } from '../../templates/default/components/job/jobs-table';
import { JobsTable as JobsTableVeraAIOperator } from '../../templates/vera-operator/components/job/jobs-table';
import { JobsTable as JobsTableEbay } from '../../templates/ebay/components/job/jobs-table';
import { JobsTable as JobsTableAuthentique } from '../../templates/authentique/components/job/jobs-table';
import { Sort } from '../../types/filter';

interface JobsTableProps {
  error: string;
  isLoading: boolean;
  onPageChange: (newPage: number) => void;
  onSortChange: (event: MouseEvent<HTMLElement>, property: string) => void;
  page: number;
  jobs: Job[];
  jobsCount: number;
  sort: Sort;
  sortBy: string;
}

export const JobsTable: FC<JobsTableProps> = (props) => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <JobsTableDefault {...props} />;
    case ETemplateType.AUTHENTIQUE:
      return <JobsTableAuthentique {...props} />;
    case ETemplateType.EBAY:
      return <JobsTableEbay {...props} />;
    case ETemplateType.VERA_AI_OPERATOR:
      return <JobsTableVeraAIOperator {...props} />;
  }
};

JobsTable.defaultProps = {
  page: 1,
  jobs: [],
  jobsCount: 0,
  sort: 'desc',
  sortBy: 'createdAt',
};

JobsTable.propTypes = {
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  jobs: Proptypes.array,
  jobsCount: Proptypes.number,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string,
};
