import type { FC } from 'react';
import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { useScanner } from '../hooks/use-scanner';

export const LastCalibrationUpdate: FC = () => {
  const { calibratedAt } = useScanner();

  if (calibratedAt) {
    return (
      <Typography variant="body2" whiteSpace="nowrap">
        Done: {format(calibratedAt, 'dd/MM/yyyy, h:mmaaa')}
      </Typography>
    );
  }

  return (
    <div>
      <Typography variant="body2" color="error" whiteSpace="nowrap">
        Not done. Execute before use.
      </Typography>
    </div>
  );
};
