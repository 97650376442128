import { RemoteFile } from './remote-file';

export enum EAssetTitle {
  // Used by mobile apps
  thumbnail = 'thumbnail',
  overview = 'overview',
  // Used by VERA AI Operator app
  FRONT_IMAGE = 'FRONT_IMAGE',
  FRONT_PREVIEW = 'FRONT_PREVIEW',
  FRONT_THUMBNAIL = 'FRONT_THUMBNAIL',
  BACK_IMAGE = 'BACK_IMAGE',
  BACK_PREVIEW = 'BACK_PREVIEW',
  // Task
  HEATMAP = 'HEATMAP',
  PROCESSED_QUERY = 'PROCESSED_QUERY',
  // SKUs
  FP_AREA = 'fp_location',
  // Others
  THUMBNAIL = 'THUMBNAIL',
  OVERVIEW = 'OVERVIEW',
  FP1 = 'fp1',
  FP2 = 'fp2',
}

export interface CreateAssetData {
  fileId: string;
  title: EAssetTitle;
  publicMetadata?: any;
  uploadTime?: number;
}

export interface BulkCreateAssetRequestData {
  tenantId: string;
  assets: CreateAssetData[];
}

export interface CreateAssetRequestData extends CreateAssetData {
  tenantId: string;
}

export interface Asset {
  id: string;
  title: EAssetTitle;
  publicMetadata?: any;
  file: RemoteFile;
  createdAt: Date;
  updatedAt: Date;
}
