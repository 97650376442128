import { FC } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Box,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Button,
} from '@material-ui/core';
import { useAuth } from '../../../hooks/use-auth';
import { usePopover } from '../../../hooks/use-popover';
import { Menu as MenuIcon } from '../../../icons/menu';
import { Logout as LogoutIcon } from '../../../icons/logout';
import { User } from '../../../types/user';
import { LastCalibrationUpdate } from './lastCalibrationUpdate';

interface AccountPopoverProps {
  currentUser?: User;
  darkMode?: boolean;
  onSwitchTheme?: () => void;
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { currentUser, darkMode, onSwitchTheme, ...other } = props;
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [anchorRef, open, handleOpen, handleClose] = usePopover();

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong');
    }
  };

  return (
    <>
      <Button
        id="navbar-button-menu"
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          cursor: 'pointer',
          display: 'flex',
          ml: 2,
          backgroundColor: '#D9D9D9',
          '&:hover': {
            backgroundColor: '#D9D9D9',
          },
        }}
        color="inherit"
        {...other}
      >
        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            display: {
              md: 'flex',
              xs: 'none',
            },
            flex: 1,
            mx: 2,
            minWidth: 90,
          }}
        >
          <MenuIcon fontSize="large" />
        </Box>
      </Button>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            minWidth: 400,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <List>
          <ListItem divider={Boolean(currentUser)}>
            <ListItemText id="navbar-menu-list-app-version" secondary={`Version: ${process.env.REACT_APP_VERSION}`} />
          </ListItem>
          {currentUser && (
            <ListItem divider>
              <ListItemText id="navbar-menu-list-user-email" secondary={currentUser.email} />
            </ListItem>
          )}
          <ListItem divider>
            <ListItemText
              id="navbar-menu-list-camera-calibration"
              primary="Camera Calibration"
              secondary={<LastCalibrationUpdate />}
              secondaryTypographyProps={{
                component: 'span',
              }}
            />
          </ListItem>
          {currentUser && (
            <ListItemButton id="navbar-menu-list-button-logout" onClick={handleLogout}>
              <ListItemText primary="Log out" />
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
            </ListItemButton>
          )}
        </List>
      </Popover>
    </>
  );
};

AccountPopover.propTypes = {
  // @ts-ignore
  currentUser: PropTypes.object,
  darkMode: PropTypes.bool,
  onSwitchTheme: PropTypes.func,
};
