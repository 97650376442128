import type { ElementType } from 'react';
import { ETemplateType } from '../templates/types';
import {
  adminItems as adminItemsDefault,
  tenantItems as tenantItemsDefault,
} from '../templates/default/utils/dashboard-menu-items';
import {
  adminItems as adminItemsAuthentique,
  tenantItems as tenantItemsAuthentique,
} from '../templates/authentique/utils/dashboard-menu-items';
import {
  adminItems as adminItemsEbay,
  tenantItems as tenantItemsEbay,
} from '../templates/ebay/utils/dashboard-menu-items';

export interface DashboardMenuItem {
  href?: string;
  external?: boolean;
  icon: ElementType;
  items?: Array<{ href: string; title: string }>;
  title: string;
}

export const getAdminItems = (): DashboardMenuItem[] => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return adminItemsDefault;
    case ETemplateType.AUTHENTIQUE:
      return adminItemsAuthentique;
    case ETemplateType.EBAY:
      return adminItemsEbay;
  }
};

export const getTenantItems = (): DashboardMenuItem[] => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return tenantItemsDefault;
    case ETemplateType.AUTHENTIQUE:
      return tenantItemsAuthentique;
    case ETemplateType.EBAY:
      return tenantItemsEbay;
  }
};
