import { useEffect, useMemo, FC } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { ChevronLeft as ChevronLeftIcon } from '../../../icons/chevron-left';
import { ChevronRight as ChevronRightIcon } from '../../../icons/chevron-right';
import { ChevronDoubleLeft as ChevronDoubleLeftIcon } from '../../../icons/chevron-double-left';

export const DEFAULT_PAGE_SIZE = 5;

interface PaginationProps {
  disabled: boolean;
  onPageChange: (newPage: number) => void;
  page: number;
  rowsCount: number;
  pageSize?: number;
  title?: string;
}

const PaginationRoot = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  padding: theme.spacing(2),
}));

export const Pagination: FC<PaginationProps> = (props) => {
  const { disabled, onPageChange, page, rowsCount, pageSize, title, ...other } = props;
  // NOTE: Usually, this should be received from the server
  const pagesCount = useMemo(
    () => Math.ceil(rowsCount / (pageSize || DEFAULT_PAGE_SIZE)),
    [rowsCount, pageSize],
  );
  const isFirstPage = useMemo(() => page <= 1, [page]);
  const isLastPage = useMemo(() => page >= pagesCount, [page, pagesCount]);

  const handlePreviousPage = () => {
    onPageChange?.(page - 1);
  };

  const handleNextPage = () => {
    onPageChange?.(page + 1);
  };

  const handleFirstPage = () => {
    onPageChange?.(1);
  };

  useEffect(() => {
    if (page > pagesCount && pagesCount > 0) {
      onPageChange?.(pagesCount);
    }

    if (page <= 0) {
      onPageChange?.(1);
    }
  }, [page, pagesCount, onPageChange]);

  return (
    <PaginationRoot
      {...other}
      sx={{
        maxHeight: 130,
        flex: 1,
        gridGap: '8px',
      }}
    >
      <Box sx={{ flexGrow: 1 }} />
      <IconButton
        id="table-pagination-first-page"
        disabled={isFirstPage || disabled}
        onClick={handleFirstPage}
        sx={{
          width: 100,
          borderRadius: 1,
        }}
      >
        <ChevronDoubleLeftIcon />
      </IconButton>
      <IconButton
        id="table-pagination-previous-page"
        disabled={isFirstPage || disabled}
        onClick={handlePreviousPage}
        sx={{
          width: 100,
          borderRadius: 1,
        }}
      >
        <ChevronLeftIcon />
      </IconButton>
      <IconButton
        id="table-pagination-next-page"
        disabled={isLastPage || disabled}
        onClick={handleNextPage}
        sx={{
          width: 100,
          borderRadius: 1,
        }}
      >
        <ChevronRightIcon />
      </IconButton>
    </PaginationRoot>
  );
};

Pagination.defaultProps = {
  disabled: false,
  page: 1,
  rowsCount: 1,
};

Pagination.propTypes = {
  disabled: PropTypes.bool,
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsCount: PropTypes.number,
  pageSize: PropTypes.number,
  title: PropTypes.string,
};
