import { ETemplateType } from '../templates/types';

/**
 * Contain arrays same distinct values?
 * @param arr1 Array 1
 * @param arr2 Array 2
 * @returns Boolean
 */
export const compareDistinctArray = (arr1: any[], arr2: any[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  return !arr1.filter((a1) => !arr2.includes(a1)).length;
};

export const getHelmetTitle = (): string => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return 'Veracity Protocol';
    case ETemplateType.AUTHENTIQUE:
      return 'Authentique';
    case ETemplateType.EBAY:
      return 'eBay Admin Portal';
    case ETemplateType.VERA_AI_OPERATOR:
      return 'VERA AI Operator';
  }
};
