import { Box, Button, Typography } from '@material-ui/core';
import { FC, useMemo } from 'react';
import { LoadingButton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import {
  CameraAltOutlined as CameraIcon,
  DoNotDisturbOutlined as DontMoveIcon,
} from '@material-ui/icons';
import { XCircle as XCircleIcon } from '../../../../icons/x-circle';
import { VP as VPIcon } from '../../../../icons/vp';
import { Analyzing as AnalyzingIcon } from '../../../../icons/analyzing';
import { Trash as TrashIcon } from '../../../../icons/trash';
import { useScanner } from '../../hooks/use-scanner';

export enum ActionsType {
  CaptureFront,
  CaptureBack,
  Capturing,
  Analyzing,
  Protected,
  UnableToProtect,
  Verified,
  NotVerified,
  Reject,
}

const actions = {
  [ActionsType.CaptureFront]: {
    background: '#000',
    color: '#fff',
    text: 'Capture FRONT Side',
    subtext: '',
    icon: (
      <CameraIcon
        sx={{
          fontSize: '1.25em',
        }}
      />
    ),
  },
  [ActionsType.CaptureBack]: {
    background: '#000',
    color: '#fff',
    text: 'Capture BACK Side',
    subtext: '',
    icon: (
      <CameraIcon
        sx={{
          fontSize: '1.25em',
        }}
      />
    ),
  },
  [ActionsType.Capturing]: {
    background: '#666',
    color: '#fff',
    text: 'Do not move the card',
    subtext: '',
    icon: (
      <DontMoveIcon
        sx={{
          fontSize: '1.25em',
        }}
      />
    ),
  },
  [ActionsType.Analyzing]: {
    background: '#D9FCFF',
    color: '#1a1a1a',
    text: 'Analyzing',
    subtext: '',
    icon: (
      <AnalyzingIcon
        sx={{
          fontSize: '1.25em',
        }}
      />
    ),
  },
  [ActionsType.Protected]: {
    background: '#00D955',
    color: '#1a1a1a',
    text: 'Protected',
    subtext: 'Scan QR code or tap to continue',
    icon: (
      <VPIcon
        sx={{
          fontSize: '1.25em',
        }}
      />
    ),
  },
  [ActionsType.UnableToProtect]: {
    background: '#CC1F2B',
    color: '#1a1a1a',
    text: 'Unable To Protect',
    subtext: 'Scan QR code or tap to continue',
    icon: (
      <XCircleIcon
        sx={{
          fontSize: '1.25em',
        }}
      />
    ),
  },
  [ActionsType.Verified]: {
    background: '#00D955',
    color: '#1a1a1a',
    text: 'Verified',
    subtext: 'Scan QR code or tap to continue',
    icon: (
      <VPIcon
        sx={{
          fontSize: '1.25em',
        }}
      />
    ),
  },
  [ActionsType.NotVerified]: {
    background: '#CC1F2B',
    color: '#1a1a1a',
    text: 'Not Verified',
    subtext: 'Scan QR code or tap to continue',
    icon: (
      <XCircleIcon
        sx={{
          fontSize: '1.25em',
        }}
      />
    ),
  },
  [ActionsType.Reject]: {
    background: '#CC1F2B',
    color: '#1a1a1a',
    text: 'Reject',
    subtext: 'Scan QR code or tap to continue',
    icon: (
      <TrashIcon
        sx={{
          fontSize: '1.25em',
        }}
      />
    ),
  },
};

interface ActionsContainerProps {
  id?: string;
  type: ActionsType;
  isLoading?: boolean;
  onClick?: () => void;
  onRejectClick?: () => void;
}

export const ActionsContainer: FC<ActionsContainerProps> = ({
  type,
  isLoading: isUserLoading,
  onClick,
  onRejectClick,
  ...others
}) => {
  const { isImageLoading: isScannerImageLoading } = useScanner();
  const isLoading = useMemo(
    () => isUserLoading ?? isScannerImageLoading,
    [isUserLoading, isScannerImageLoading],
  );
  const { background, color, text, subtext, icon } =
    actions[isLoading ? ActionsType.Capturing : type];

  const rejectButton = useMemo(() => {
    const { background: bg, color: c, text: t, icon: i } = actions[ActionsType.Reject];
    return (
      <Button
        fullWidth
        color="secondary"
        size="large"
        variant="contained"
        onClick={onRejectClick}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gridGap: '1em',
          height: '100%',
          backgroundColor: `${bg} !important`,
          pointerEvents: onClick ? 'initial' : 'none',
          '.MuiLoadingButton-loadingIndicator': {
            display: 'none',
          },
        }}
        {...others}
        id={`${others.id}-reject`}
      >
        <Typography
          variant="h4"
          sx={{
            color: c,
            display: 'flex',
            alignItems: 'center',
            gridGap: '0.25em',
          }}
        >
          {i}
          {t}
        </Typography>
      </Button>
    );
  }, [onRejectClick]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gridColumn: type === ActionsType.Protected ? 'span 1' : 'span 2',
        }}
      >
        <LoadingButton
          loading={isLoading}
          fullWidth
          color="secondary"
          size="large"
          variant="contained"
          onClick={onClick}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gridGap: '1em',
            height: '100%',
            backgroundColor: `${background} !important`,
            pointerEvents: onClick ? 'initial' : 'none',
            '.MuiLoadingButton-loadingIndicator': {
              display: 'none',
            },
          }}
          {...others}
        >
          <Typography
            variant="h3"
            sx={{
              color,
              display: 'flex',
              alignItems: 'center',
              gridGap: '0.25em',
            }}
          >
            {icon}
            {text}
          </Typography>
          <Typography
            variant="h6"
            fontWeight={400}
            sx={{
              color,
            }}
          >
            {subtext}
          </Typography>
        </LoadingButton>
      </Box>
      {type === ActionsType.Protected && <Box>{rejectButton}</Box>}
    </>
  );
};

ActionsContainer.propTypes = {
  id: PropTypes.string,
  type: PropTypes.any.isRequired,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  onRejectClick: PropTypes.func,
};
