import { RemoteFile } from './remote-file';

export enum EFingerpintTitle {
  PROTECTION = 'protection',
  PROTECTION_FRONT = 'protection_front',
  PROTECTION_BACK = 'protection_back',
  REGISTRATION = 'registration',
}

export interface FingerprintLocation {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface FingerprintPublicMetadata {
  location?: FingerprintLocation;
  [key: string]: any;
}

export interface Fingerprint {
  id: string;
  title: string;
  publicMetadata: FingerprintPublicMetadata;
  createdAt: Date;
  file: RemoteFile;
}
