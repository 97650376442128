import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Divider, Toolbar, Typography } from '@material-ui/core';
import { AccountPopover } from '../../../../components/account-popover';
import { Logo } from '../../../../components/logo';

export const LoginNavbar: FC = () => (
  <AppBar elevation={0} sx={{ color: 'text.primary', backgroundColor: '#ffffff' }}>
    <Toolbar
      disableGutters
      sx={{
        alignItems: 'normal',
        display: 'flex',
        minHeight: '120px !important',
        pl: 3,
      }}
    >
      <Box
        component={RouterLink}
        to="/"
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          width: 90,
        }}
      >
        <Logo emblemOnly variant="dark" size="large" />
      </Box>
      <Divider
        flexItem
        orientation="vertical"
        sx={{
          // borderWidth: '2px',
          borderColor: '#D9D9D9',
          mx: 3,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography id="login-navbar-title" variant="h5">Log In</Typography>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <AccountPopover currentUser={null} />
    </Toolbar>
    <Divider
      sx={{
        borderColor: '#D9D9D9',
      }}
    />
  </AppBar>
);
