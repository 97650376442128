import type { ElementType } from 'react';
import { HorizontalSplitRounded as JobsIcon, LayersRounded as SKUsIcon } from '@material-ui/icons';
import { CustomCube as ItemsIcon } from '../../../icons/custom-cube';
import { CustomUsers as UsersIcon } from '../../../icons/custom-users';
import { OfficeBuilding as TenantsIcon } from '../../../icons/office-building';

export interface DashboardMenuItem {
  href?: string;
  external?: boolean;
  icon: ElementType;
  items?: Array<{ href: string; title: string }>;
  title: string;
}

export const adminItems: DashboardMenuItem[] = [
  {
    icon: UsersIcon,
    title: 'All Users',
    href: '/users',
  },
  {
    icon: TenantsIcon,
    title: 'Tenants',
    href: '/tenants',
  },
];

export const tenantItems: DashboardMenuItem[] = [
  {
    icon: JobsIcon,
    title: 'Events',
    href: '/tenants/:tenantId/events',
  },
  {
    icon: ItemsIcon,
    title: 'Items',
    href: '/tenants/:tenantId/items',
  },
  {
    icon: SKUsIcon,
    title: 'SKUs',
    href: '/tenants/:tenantId/skus',
  },
  {
    icon: UsersIcon,
    title: 'Users',
    href: '/tenants/:tenantId/users',
  },
];
